import { Disclosure } from "@headlessui/react";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

import { AuthContext } from "./AuthContext";
import { auth, getStripePortalLink } from "../firebase";
import { useContext } from "react";
import Modal from "./Modal";
import React from "react";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

export default function NavBar() {
  const { user, dbUser, loadingUser } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Disclosure as="nav" className="bg-gray-900 shadow-lg">
      {({ open }) => (
        <>
          {modalOpen && (
            <Modal onClose={() => setModalOpen(false)} open={modalOpen} />
          )}

          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center w-full">
              <div className="flex">
                <a className="flex flex-shrink-0 items-center" href="/">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src="/logo1.svg"
                    alt="VidSummary"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="/logo1.svg"
                    alt="VidSummary"
                  />
                  <h1 className="text-white ml-3 text-lg md:text-2xl font-[500]">
                    VidSummary
                  </h1>
                </a>
              </div>
              <div className="ml-auto">
                <div className="flex space-x-1">
                  {!loadingUser &&
                    dbUser?.subscriptionTier !== "premium" &&
                    user && (
                      <a
                        className={classNames(
                          "bg-gray-700 text-white bg-opacity-50",
                          "rounded-md px-2 py-2 text-xs md:text-sm font-[500] cursor-pointer hover:bg-gray-700"
                        )}
                        onClick={() => setModalOpen(true)}
                      >
                        Try Premium 💎
                      </a>
                    )}

                  {user && dbUser?.subscriptionTier === "premium" && (
                    <a
                      className={classNames(
                        "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "rounded-md px-2 py-2 text-xs md:text-sm font-[500] cursor-pointer leading-tight"
                      )}
                      href={getStripePortalLink(user.email || "")}
                    >
                      My Premium
                    </a>
                  )}

                  {!user && (
                    <a
                      className={classNames(
                        "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "rounded-md px-2 py-2 text-xs md:text-sm font-[500] cursor-pointer leading-tight"
                      )}
                      onClick={signInWithGoogle}
                    >
                      Sign In
                    </a>
                  )}
                  {user && (
                    <a
                      className={classNames(
                        "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "rounded-md px-2 py-2 text-xs md:text-sm cursor-pointer tracking-tight leading-tight"
                      )}
                      onClick={handleSignOut}
                    >
                      Log Out
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
