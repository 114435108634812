import React, { useContext } from "react";
import { AuthContext } from "./AuthContext";

const loading = (
  <div className="opacity-20">
    <h3 className="h-2 bg-gray-700 w-3/4 animate-pulse rounded-sm"></h3>
    <ul className="mt-1 animate-pulse ">
      <li className="w-full h-1 bg-gray-700 rounded-sm"></li>
      <li className="w-full h-1 bg-gray-700 rounded-sm"></li>
      <li className="w-full h-1 bg-gray-700 rounded-sm"></li>
    </ul>
  </div>
);
export default function UsageIndicator() {
  const { dbUser, user } = useContext(AuthContext);
  if (!dbUser) return null;
  if (dbUser.creditsUsed === 0) return null;
  if (user && !dbUser) return loading;
  const { creditsUsed, monthlyCredits } = dbUser;
  const percentage = Math.round((creditsUsed / monthlyCredits) * 100);
  const creditsLeft = monthlyCredits - creditsUsed;
  return (
    <div>
      <div className="flex justify-between mb-1">
        <span className="text-xs font-[400] text-white opacity-80">
          Monthly Usage:
        </span>
        <span className="text-xs font-[400] text-white opacity-80">
          {creditsLeft} left
        </span>
      </div>

      <div className="w-full bg-black rounded-full  dark:bg-gray-700 bg-opacity-10">
        <div
          className="bg-gradient-to-r from-blue-600  via-purple-600 rounded-full opacity-80 h-2 text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
}
