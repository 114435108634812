import { AuthContext } from "./AuthContext";
import { useContext } from "react";
import { classNames, signInWithGoogle } from "./NavBar";
import { fetchSummary } from "../firebase";
import getVideoId from "get-video-id";
import { toast } from "react-toastify";

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UsageIndicator from "./UsageIndicator";
import Modal from "./Modal";

export default function CallToAction() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);

  const [url, setUrl] = React.useState("");
  const [clicked, setClicked] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState(
    "Fetching video info..."
  );
  const { pathname } = useLocation();
  const { user, dbUser } = useContext(AuthContext);
  const creditsLeft = dbUser
    ? dbUser.monthlyCredits - dbUser.creditsUsed
    : null;
  const [loading, setLoading] = React.useState(false);
  const [fetchedCaptions, setFetchedCaptions] = React.useState(false);
  const onSummarize = async () => {
    if (!url) return null;
    setClicked(true);
    if (!user) return await signInWithGoogle();
    fetchCaptions();
  };

  React.useEffect(() => {
    if (pathname === "/payment-success") {
      toast.success("Premium activated successfully", {
        position: "top-center",
        autoClose: 3500,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
      });
      navigate("/", { replace: true });
    }
  }, []);

  React.useEffect(() => {
    if (clicked && user && !fetchedCaptions) {
      fetchCaptions();
    }
  }, [user]);

  const fetchCaptions = async () => {
    const { id, service } = getVideoId(url);
    const videoId = id;
    if (!videoId || service !== "youtube")
      return alert("Please enter a valid youtube link");
    setLoading(true);
    setFetchedCaptions(true);
    try {
      const data = await fetchSummary({ videoId });
      navigate(`/summary/${videoId}`, { state: data.data });
    } catch (err: any) {
      if (err.message) {
        toast(err.message, {
          position: "top-center",
          autoClose: 3500,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
      } else {
        console.error(err.toString(), {});
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (loading === true) {
      setTimeout(() => setLoadingText("Processing transcription..."), 2500);
      setTimeout(() => setLoadingText("Analyzing text..."), 5000);
      setTimeout(() => setLoadingText("Generating summary..."), 8500);
    }
  }, [loading]);

  return (
    <div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} open={modalOpen} />
      )}
      <div className="mx-auto max-w-7xl py-16 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md  lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-[500] tracking-wide text-white sm:text-4xl">
              AI-Powered YouTube Summaries.
            </h2>
            {!(creditsLeft === 0) && (
              <p className="mt-6 text-lg leading-8 text-gray-300 font-[500]">
                Instantly transform videos into concise text highlights.
                <br />
              </p>
            )}

            <div className="mt-12">
              {creditsLeft === 0 ? (
                <p className="text-md leading-8 text-gray-300 font-[500]">
                  You have reached your usage limit for this month.
                  {dbUser?.subscriptionTier === "free" && (
                    <span
                      onClick={() => setModalOpen(true)}
                      className="cursor-pointer text-blue-400"
                    >
                      {" "}
                      Upgrade To Premium
                    </span>
                  )}
                  {dbUser?.subscriptionTier === "premium" && (
                    <span> Contact us for a higher usage tier.</span>
                  )}
                </p>
              ) : (
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  placeholder="Enter a Youtube link"
                  className="block w-full rounded-md border-0 py-3 px-2 text-gray-900 font-[500] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              )}
            </div>

            <div className="mt-8 flex gap-x-6 justify-start mb-20">
              {!(creditsLeft === 0) && (
                <>
                  {!loading && (
                    <a
                      className="rounded-md bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 px-3.5 py-2.5 text-sm font-semibold text-neutral-200 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
                      onClick={onSummarize}
                    >
                      Summarize
                    </a>
                  )}
                </>
              )}
              {loading && (
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <div className="text-white opacity-70">{loadingText}</div>
                </div>
              )}
              <div
                className={classNames(
                  "w-1/2 md:w-1/3",
                  `${!(creditsLeft === 0) ? "ml-auto" : ""}`
                )}
              >
                <UsageIndicator />
              </div>
            </div>
          </div>

          <div className="hidden lg:block relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute top-0 left-0 w-[57rem] max-w-none rounded-md bg-white/5"
              src="homepage-pic.avif"
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
