import React from "react";

export default function Footer() {
  return (
    <footer style={{ width: "100%" }}>
      <div className="px-4 py-1 md:px-4 w-full mx-auto max-w-screen-xl flex items-center">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 block">
          © 2023{" "}
          <a href="/" className="hover:underline">
            VidSummary
          </a>
        </span>
        <ul className="flex flex-wrap items-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0 ml-auto">
          <li>
            <a href="/privacy-policy" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:underline">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
