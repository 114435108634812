import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { fetchSummary } from "../firebase";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";
import { signInWithGoogle } from "./NavBar";
import { ChevronLeftIcon, LinkIcon } from "@heroicons/react/24/outline";

export interface SummaryDocument {
  channelLink: string;
  channelName: string;
  summaries: string[];
  title: string;
  videoId: string;
  createdAt: string;
}

export default function Summary() {
  const { videoId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState<SummaryDocument | null>(state);
  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    if (state) return;
    if (videoId && user) onFetchSummary();
  }, [user, videoId, user]);

  const onFetchSummary = async () => {
    setLoading(true);
    const data: any = await fetchSummary({ videoId });
    let summary: SummaryDocument = data.data;
    if (data.data) setSummary(data.data);
    setLoading(false);
  };

  return (
    <div>
      <div className="mx-auto max-w-7xl sm:px-6 py-2 md:py-10 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-8 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-8 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.1"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto text-left lg:mx-0 lg:flex-auto lg:py-16 pb-20">
            {!user && (
              <>
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  <b>Please sign in to view summary</b>
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  You are currently signed out.{" "}
                  <span
                    onClick={signInWithGoogle}
                    className="cursor-pointer text-blue-400"
                  >
                    Click here
                  </span>{" "}
                  to sign in.
                </p>
              </>
            )}
            {/* Nav Summary */}
            {user && summary && (
              <>
                <div
                  className="flex items-center
                "
                >
                  <ChevronLeftIcon
                    className="h-6 md:h-6 text-white opacity-40 block mr-2 cursor-pointer"
                    onClick={() => navigate("/")}
                  />
                  <h2 className="text-sm md:text-md font-bold tracking-tight text-white opacity-40 block">
                    <b>Summary of {summary.channelName}'s video</b>
                  </h2>
                </div>

                <h2 className="md:ml-4 text-lg font-bold tracking-tight text-white md:text-2xl mt-6 flex items-center">
                  <b>{summary.title}</b>{" "}
                  <a href={`https://youtu.be/${videoId}`} target="_blank">
                    <LinkIcon className="ml-3 h-4 block cursor-pointer" />
                  </a>
                </h2>
                <div className="mt-6 text-white opacity-85 text-md md:text-lg font-light md:bg-black md:bg-opacity-10 md:p-4 rounded-lg tracking-wider">
                  {summary.summaries.map((s, idx) => (
                    <>
                      <p key={idx}>{s}</p>
                      <br />
                    </>
                  ))}
                </div>
              </>
            )}
            {!summary && videoId && (
              <>
                <h3 className="h-8 mt-6 bg-gray-700 w-3/4 animate-pulse rounded-sm"></h3>

                <ul className="mt-12 space-y-3 animate-pulse ">
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                </ul>

                <ul className="mt-6 space-y-3 animate-pulse ">
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                </ul>

                <ul className="mt-6 space-y-3 animate-pulse ">
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                  <li className="w-full h-4 bg-gray-700 rounded-sm"></li>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
