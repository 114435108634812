import React from "react";
import { AppLayout } from "../App";
import Footer from "../components/Footer";

export default function Contact() {
  return (
    <AppLayout>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 sm:py-12 lg:px-8 text-white pb-24">
        {" "}
        <h1 className="text-lg">Get in touch</h1> <br />
        <p>
          Reach us via email at{" "}
          <span>
            <u>vidsummary@gmail.com</u>
          </span>{" "}
          for any questions or feature requests.
        </p>
      </div>
    </AppLayout>
  );
}
