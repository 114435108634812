import React from "react";
import { AppLayout } from "../App";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  return (
    <AppLayout>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 sm:py-12 lg:px-8 text-white pb-24">
        {" "}
        <h1>Privacy Policy for VidSummary</h1> <br />
        <p>
          At VidSummary, we are committed to protecting the privacy of our
          users. This privacy policy explains how we collect, use, and protect
          your personal information when you use our web app.
        </p>
        <br />
        <b>1. Information We Collect</b>
        <br />
        <br />
        <p>
          When you use VidSummary, we may collect the following information:
        </p>
        <ul>
          <li>
            Personal Information: We may collect your name and email address
            when you sign up for an account with us.
          </li>
          <li>
            Usage Information: We may collect information about how you use our
            web app, including the videos you watch and the summaries you
            create.
          </li>
          <li>
            Device Information: We may collect information about the device you
            use to access our web app, including the type of device, operating
            system, and browser.
          </li>
        </ul>
        <br />
        <b>2. How We Use Your Information</b>
        <br />
        <br />
        <p>We may use the information we collect for the following purposes:</p>
        <ul>
          <li>To provide you with access to our web app and its features.</li>
          <li>To personalize your experience with our web app.</li>
          <li>To improve our web app and its features.</li>
          <li>
            To communicate with you about our web app and any updates or changes
            to our policies.
          </li>
        </ul>
        <br />
        <b>3. How We Protect Your Information</b>
        <br />
        <br />
        <p>
          We take reasonable steps to protect your personal information from
          unauthorized access, use, or disclosure. We use encryption and other
          security measures to protect your data.
        </p>
        <br />
        <b>4. Disclosure of Your Information</b>
        <br />
        <br />
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. We may disclose your personal information in the following
          circumstances:
        </p>
        <ul>
          <li>With your consent.</li>
          <li>
            To comply with legal obligations, such as a court order or subpoena.
          </li>
          <li>To protect our rights or property.</li>
          <li>To enforce our policies or agreements.</li>
        </ul>
        <br />
        <b>5. Third-Party Links</b>
        <br />
        <br />
        <p>
          Our web app may contain links to third-party websites or services. We
          are not responsible for the privacy practices or content of these
          third-party websites or services.
        </p>
        <br />
        <b>6. Changes to Our Privacy Policy</b>
        <br />
        <br />
        <p>
          We may update our privacy policy from time to time. If we make any
          material changes to our privacy policy, we will notify you by email or
          by posting a notice on our web app.
        </p>
        <br />
        <b>7. Contact Us</b>
        <br />
        <br />
        <p>
          If you have any questions or concerns about our privacy policy, please
          contact us at turnatker@gmail.com.
        </p>
        <p>
          By using our web app, you agree to the terms of this privacy policy.
        </p>
      </div>
    </AppLayout>
  );
}
