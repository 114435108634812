import React from "react";
import { AppLayout } from "../App";
import CallToAction from "../components/CallToAction";

export default function Main() {
  return (
    <AppLayout>
      <CallToAction />
    </AppLayout>
  );
}
