import React, { createContext, useState, useEffect, ReactNode } from "react";
import { auth, fetchUser, setUserIdAnalytics } from "../firebase";
import { User } from "firebase/auth";

export interface dbUser {
  subscriptionTier: "free" | "premium";
  monthlyCredits: number; //this will change based on free or premium
  creditsUsed: number;
  email: string;
  phoneNumber: string;
  createdAt: string;
  userId: string;
}

interface AuthContextType {
  user: User | null;
  dbUser: dbUser | null;
  loadingUser: boolean;
}

const defaultValue: AuthContextType = {
  user: null,
  dbUser: null,
  loadingUser: false,
};

export const AuthContext = createContext<AuthContextType>(defaultValue);

interface AuthProviderProps {
  children: ReactNode;
}
const AuthProvider = ({ children }: AuthProviderProps) => {
  const localStorageUser = localStorage.getItem("user");
  const userParsed = localStorageUser ? JSON.parse(localStorageUser) : null;
  const [user, setUser] = useState<User | null>(userParsed || null);
  const [dbUser, setDbUser] = useState<dbUser | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const uid = user?.uid;
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user?.uid) setUserIdAnalytics(user?.uid);
      localStorage.setItem("user", JSON.stringify(user));
      if (user?.uid) fetchFirebaseUser(user?.uid);
    });
    return () => unsubscribe();
  }, []);

  const fetchFirebaseUser = async (uid: string) => {
    const res = await fetchUser({ userId: uid });
    if (res.data) setDbUser(res.data as dbUser);
    setLoadingUser(false);
  };
  return (
    <AuthContext.Provider value={{ user, dbUser, loadingUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
