import React, { useContext } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { getStripeTableInfo } from "../firebase";
import { AuthContext } from "./AuthContext";

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  email?: string;
}
export default function Modal({ onClose, open, email = "" }: ModalProps) {
  const { user, dbUser } = useContext(AuthContext);
  if (!open) return null;
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity"></div>

      <div className="fixed inset-0 z-10">
        <div className="flex min-h-full justify-center text-center items-center">
          <div className="rounded-lg bg-slate-900 shadow-xl transition-all w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 m-8">
            <div className="flex">
              <div
                className="ml-auto mr-4 mt-4 cursor-pointer"
                onClick={onClose}
              >
                <XCircleIcon className="text-gray-200 h-6 opacity-70" />
              </div>
            </div>

            <div
              className="pb-2"
              dangerouslySetInnerHTML={{
                __html: `
                        <stripe-pricing-table 
                         pricing-table-id="${
                           getStripeTableInfo().pricingTableId
                         }"
                         publishable-key="${
                           getStripeTableInfo().publishableKey
                         }"
                         customer-email="${user?.email}"
                         >
                        
                        </stripe-pricing-table>
                    `,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
