// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserId,
} from "firebase/analytics";
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHhWcnuOSd9qJnGlt4MbKo7OaOSc3dKro",
  authDomain: "vidsummary-9c30b.firebaseapp.com",
  projectId: "vidsummary-9c30b",
  storageBucket: "vidsummary-9c30b.appspot.com",
  messagingSenderId: "365454430097",
  appId: "1:365454430097:web:9a2f4ad257519503809430",
  measurementId: "G-KJ2GE0CDYJ",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const functions = getFunctions(app);
let analytics: Analytics | undefined;
if (!isDev) analytics = getAnalytics(app);
//functions here
export const fetchSummary = httpsCallable(functions, "summariseCaptions");
export const fetchUser = httpsCallable(functions, "getUser");

export function pageViewEvent(path: string) {
  if (!analytics) return;
  logEvent(analytics, "page_view", { page_path: path });
}

export function setUserIdAnalytics(userId: string) {
  if (!analytics) return;
  setUserId(analytics, userId);
}

export function getStripeTableInfo() {
  if (isDev) {
    return {
      pricingTableId: "prctbl_1MrxqxBUwcAFYHhEMrajbkzg",
      publishableKey:
        "pk_test_51MqFOCBUwcAFYHhEiMin1bLRGZ5cDbrWNzDPD0gE3GAeM8CGH4ChIMPlU5NAIi9qZKNribmHmhjrFgXZh6YbtPG900XzD8zYPi",
    };
  }

  return {
    pricingTableId: "prctbl_1Mrwr0BUwcAFYHhE16qsgfUg",
    publishableKey:
      "pk_live_51MqFOCBUwcAFYHhEr8squEyndu7rG0EB5NsxM9YEV20MuPZDVoW1BXbjwArtSlXPESJyHG6oQKMLQSB1fTJm7U2A00w1o5lP7d",
  };
}

export function getStripePortalLink(email: string) {
  if (isDev) {
    return `https://billing.stripe.com/p/login/test_aEUbJs9EY2gf09ibII?prefilled_email=${encodeURIComponent(
      email
    )}`;
  }

  return `https://billing.stripe.com/p/login/8wMg1kbX12c13yodQQ?prefilled_email=${encodeURIComponent(
    email
  )}`;
}
