import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import CallToAction from "./components/CallToAction";
import Summary from "./components/Summary";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { pageViewEvent } from "./firebase";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import NotFound from "./pages/404";
import Main from "./pages/Main";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFound />,
  },
  {
    path: "/payment-success",
    element: <Main />,
  },
  {
    path: "/summary/:videoId",
    element: (
      <AppLayout>
        <Summary />
      </AppLayout>
    ),
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

export function AppLayout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  React.useEffect(() => {
    pageViewEvent(pathname);
  }, [pathname]);
  return (
    <div className="h-full">
      <NavBar />
      <div className="flex flex-col" style={{ height: "calc(100% - 64px)" }}>
        <div className="flex-grow">{children}</div>
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}
function App() {
  return <RouterProvider router={router} />;
}

export default App;
